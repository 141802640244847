export const domReady = (onReady) => {
  window.requestAnimationFrame(async function check() {
    document.body ? await onReady() : window.requestAnimationFrame(check);
  });
};

export const initSections = (getSectionsOnReady, onInit) => {
  const init = async () => {
    const sections = await getSectionsOnReady();
    if (!sections) return;

    for (const section of sections) {
      await onInit(section);
    }
  };

  domReady(init);
};

export const initSectionsWithIntersection = (getSectionsOnReady, onIntersection) => {
  const init = async () => {
    const sections = await getSectionsOnReady();
    if (!sections) return;

    const observer = new IntersectionObserver(
      async (entries, observer) => {
        for (const entry of entries) {
          if (!entry.isIntersecting) continue;
          observer.unobserve(entry.target);
          await onIntersection(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.01,
      },
    );

    for (const section of sections) {
      observer.observe(section);
    }
  };

  domReady(init);
};

import {initSectionsWithIntersection} from '@scripts/helpers/block.js';

const initializeBlock = async (section) => {
  // const headerWrapper = section.querySelector('.b-homepage-about-us__header-wrapper');
  const desc = section.querySelector('.b-homepage-about-us__desc');

  const {gsap} = await import('gsap');
  const {ScrollTrigger} = await import('gsap/ScrollTrigger');
  const {SplitText} = await import('gsap/SplitText');

  gsap.registerPlugin(ScrollTrigger, SplitText);

  const split = new SplitText(desc, {
    type: 'chars',
    tag: 'span',
  });

  gsap.from(split.chars, {
    duration: 0.05,
    opacity: 0.25,
    stagger: 0.01,
    ease: 'linear',
    scrollTrigger: {
      trigger: desc,
      markers: false,
      scrub: 1,
      start: 'top bottom',
      end: 'bottom 50%',
    },
  });

  // gsap.fromTo(
  //   headerWrapper,
  //   {
  //     y: '-4.8rem',
  //   },
  //   {
  //     duration: 1,
  //     y: '4.8rem',
  //     ease: 'Power3.inOut',
  //     scrollTrigger: {
  //       trigger: headerWrapper?.parentElement,
  //       markers: false,
  //       scrub: true,
  //       start: 'top bottom',
  //       end: 'bottom top',
  //     },
  //   },
  // );
  //
  // const lines = Array.from(section.querySelectorAll('[data-gradient-anim]'));
  //
  // let i = 0;
  // for (const line of lines) {
  //   i++;
  //   const delay = i * 0.25;
  //   gsap.fromTo(
  //     line,
  //     {
  //       rotate: 0,
  //     },
  //     {
  //       rotate: 360,
  //       duration: 2,
  //       ease: 'linear',
  //       repeat: -1,
  //       delay: delay,
  //     },
  //   );
  // }
};

initSectionsWithIntersection(async () => {
  return Array.from(document.getElementsByClassName('b-homepage-about-us'));
}, initializeBlock);

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
